@font-face {
  font-family: "Montserrat";
  src: local("../assets/fonts/Montserrat-Regular.ttf"),
    url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-md";
  src: local("../assets/fonts/Montserrat-Medium.ttf"),
    url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-sb";
  src: local("../assets/fonts/Montserrat-SemiBold.ttf"),
    url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-eb";
  src: local("../assets/fonts/Montserrat-ExtraBold.ttf"),
    url("../assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-bl";
  src: local("../assets/fonts/Montserrat-Black.ttf"),
    url("../assets/fonts/Montserrat-Black.ttf") format("truetype");
}
