.header {
  background: var(--color-dark);
  padding: 30px;
  color: var(--color-white);
  border-bottom: 4px solid var(--color-gold);
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  aside {
    display: flex;
    align-items: center;
    gap: 20px;

    ul {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.header__left {
  img {
    height: 80px;
    width: auto;
  }

  h2 {
    @media (max-width: 520px) {
      width: 300px !important;
    }
  }
}

.header__burger {
  display: flex;
  flex-direction: column;
  gap: 5px;

  div {
    width: 40px;
    height: 5px;
    background: var(--color-white);
  }
}

.header__right {
  ul {
    li {
      a,
      button {
        padding: 10px 20px;
        background: var(--color-white);
        color: var(--color-dark);
        font-size: 20px;
        font-family: "Montserrat-eb", sans-serif;
        font-weight: 800;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

      @media (max-width: 1850px) {
        &:not(:last-child) {
          display: none;
        }
      }
    }
  }
}

.header__right > a {
  font-size: 30px;
  &:hover {
    text-decoration: underline;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.header__contacts {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  left: 0;
  top: 0;

  background: rgba(0, 0, 0, 0.6);

  z-index: 9999;

  animation-name: opacity;
  animation-duration: 0.3s;
}

.header__contacts__wrapper {
  position: relative;

  width: 70vw;
  height: 800px;

  background: var(--color-white);
  border-radius: 20px;
  color: var(--color-dark);
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  p,
  a {
    display: flex;
    align-items: center;
    gap: 5px;

    font-size: 24px;
    font-family: "Montserrat-md", sans-serif;
    color: var(--color-light-brown);
    font-weight: 500;

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
  }

  a {
    text-decoration: underline;
  }

  iframe {
    border-radius: 10px;
  }
}

.header__contacts__close {
  width: 50px;
  height: 50px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 93%;

  &::before {
    content: "";
    position: absolute;

    width: 100%;
    height: 2px;

    background: var(--color-dark);
    transform: rotate(45deg);
  }

  &::after {
    content: "";
    position: absolute;

    width: 100%;
    height: 2px;

    background: var(--color-dark);
    transform: rotate(-45deg);
  }
}
