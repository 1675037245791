.contact_us {
  margin-top: 80px;
}

.contact_us__wrapper {
  h2 {
    font-size: 36px;
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    gap: 20px;
    margin-top: 40px;

    input,
    textarea {
      padding: 10px 5px;
      background: #e8e8e8;
      border: 1px solid #e3e3e3;
      width: 900px;
    }

    textarea {
      height: 250px;
      resize: none;
    }
  }

  button {
    padding: 10px;
    width: 900px;
    background: linear-gradient(
      90deg,
      rgba(190, 134, 60, 1) 0%,
      rgba(236, 200, 124, 1) 46%,
      rgba(236, 200, 124, 1) 61%,
      rgba(190, 134, 60, 1) 100%
    );
    color: var(--color-dark);
    font-size: 20px;
    font-family: "Moynserrat-sb", sans-serif;
    font-weight: 600;
    margin-top: 40px;
  }
}
