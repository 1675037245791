.companies__main {
  display: flex;
  gap: 150px;
}

.companies__left {
  position: relative;

  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    margin-top: 60px;

    li {
      p {
        color: var(--color-white);
        font-size: 24px;
        font-family: "Montserrat-bd", sans-serif;
        font-weight: 700;
      }
    }
  }

  a {
    position: absolute;
    background: linear-gradient(
      90deg,
      rgba(190, 134, 60, 1) 0%,
      rgba(236, 200, 124, 1) 46%,
      rgba(236, 200, 124, 1) 61%,
      rgba(190, 134, 60, 1) 100%
    );
    padding: 10px 30px;
    color: var(--color-dark);
    font-size: 24px;
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;
    top: 95%;
  }

  &::before {
    content: "";
    position: absolute;
    left: -250%;
    width: 800px;
    height: 100%;
    background: var(--color-dark);
    z-index: -1;
  }
}

.companies__right {
  margin-top: 60px;

  h2 {
    margin-top: 100px;
    font-size: 42px;
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 24px;
    font-family: "Montserrat-md", sans-serif;
    font-weight: 500;

    li {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background: var(--color-dark);
        left: -2%;
        top: 25%;
      }
    }
  }
}

.companies__wrapper > h2 {
  font-size: 48px;
  font-family: "Montserrat-bd", sans-serif;
  font-weight: 700;
  text-align: center;

  margin-top: 250px;

  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    right: 50%;
    top: -15%;
    transform: translate(-50%, 50%);
    width: 600px;
    height: 5px;
    background: linear-gradient(
      90deg,
      rgba(190, 134, 60, 1) 0%,
      rgba(236, 200, 124, 1) 46%,
      rgba(236, 200, 124, 1) 61%,
      rgba(190, 134, 60, 1) 100%
    );
  }
}
