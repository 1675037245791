.services {
  padding: 40px;
  background: var(--color-dark);
  color: var(--color-white);
  margin-top: 60px;
}

.services__wrapper {
  h2 {
    font-size: 42px;
    font-family: "Montserrat-md", sans-serif;
    font-weight: 500;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-left: 40px;
    margin-top: 40px;

    li {
      position: relative;
      margin-left: 20px;

      p {
        font-size: 32px;
        font-family: "Montserrat-sb", sans-serif;
        font-weight: 600;
      }

      &::before {
        content: "";
        position: absolute;
        left: -4%;
        top: 25%;
        width: 30px;
        height: 30px;
        background: linear-gradient(
          90deg,
          rgba(190, 134, 60, 1) 0%,
          rgba(236, 200, 124, 1) 46%,
          rgba(236, 200, 124, 1) 61%,
          rgba(190, 134, 60, 1) 100%
        );
      }
    }
  }
}
