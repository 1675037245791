.team {
  margin-top: 100px;
}

.team__wrapper {
  h3 {
    font-size: 24px;
    font-family: "Montserrat-sb", sans-serif;
    font-weight: 600;
  }
}

.team__modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  transition: all 0.2s ease;

  z-index: 9999;
}

.team__list {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;

    li {
      img {
        width: 15.625rem;
        height: 21.875rem;
        object-fit: cover;
      }

      position: relative;
      transform-style: preserve-3d;

      &::before {
        content: "";
        position: absolute;

        left: -5%;
        top: -2%;

        transform: translateZ(-1px);

        width: 100%;
        height: 80%;

        background: linear-gradient(
          90deg,
          rgba(190, 134, 60, 1) 0%,
          rgba(236, 200, 124, 1) 46%,
          rgba(236, 200, 124, 1) 61%,
          rgba(190, 134, 60, 1) 100%
        );

        @media (max-width: 1180px) {
          display: none;
        }
      }

      h4 {
        font-size: 20px;
        font-family: "Montserrat-sb", sans-serif;
        font-weight: 600;
        color: var(--color-white);
        margin-top: 10px;
      }

      button {
        padding: 10px 20px;
        background: linear-gradient(
          90deg,
          rgba(190, 134, 60, 1) 0%,
          rgba(236, 200, 124, 1) 46%,
          rgba(236, 200, 124, 1) 61%,
          rgba(190, 134, 60, 1) 100%
        );
        color: var(--color-dark);
        margin-top: 20px;
        font-size: 16px;
        font-family: "Montserrat-sb", sans-serif;
        font-weight: 600;

        transition: 0.2s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    @media (max-width: 1180px) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
    }
  }
}

.team__modal__info {
  width: 800px;
  height: fit-content;
  padding: 20px;
  background: var(--color-white);
  border-radius: 10px;
}

.team__modal__close {
  width: 40px;
  height: 40px;
  background: none;

  position: relative;
  margin-left: 800px;
  padding: 20px;

  &::before {
    content: "";
    position: absolute;

    width: 40px;
    height: 2px;

    transform: rotate(45deg);

    background: var(--color-white);
  }

  &::after {
    content: "";
    position: absolute;

    width: 40px;
    height: 2px;

    transform: rotate(-45deg);

    background: var(--color-white);
  }
}

.team__list {
  background: #000;
  padding: 120px 40px;
  margin-top: 40px;
}
