.footer {
  margin-top: 80px;
  background: #000;
  padding: 60px;
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 24px;
    font-family: "Montserrat-sb", sans-serif;
    font-weight: 600;
    color: var(--color-white);
    width: 600px;
  }
}

.footer__wrapper > a {
  img {
    height: 80px;
    width: auto;
  }
}

.footer__contacts {
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 10px;

  a {
    &:first-child {
      font-size: 20px;
      font-family: "Montserrat-md", sans-serif;
      font-weight: 500;
      color: var(--color-white);
    }

    &:not(:first-child) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      width: 60px;
      height: 60px;

      display: inline-flex;

      border: 1px solid var(--color-light-brown);
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
}

.footer__copyright {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  color: var(--color-white);
}

.footer__data {
  margin-top: 20px;
}

.footer__data__wrapper {
  display: flex;
  gap: 20px;
  color: var(--color-white);
}
