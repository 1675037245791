.single_price {
  margin-top: 60px;
}

.single_price__wrapper {
  display: flex;
  flex-direction: column;
  height: 900px;

  .single_price__bottom {
    margin-top: auto;
  }
}

.single_price__wrapper > ul {
  display: flex;
  flex-direction: column;
  gap: 60px;

  li {
    h3 {
      font-size: 28px;
      font-family: "Montserrat-bd", sans-serif;
      font-weight: 700;
      width: 600px;
      margin-left: 30px;

      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background: linear-gradient(
          90deg,
          rgba(190, 134, 60, 1) 0%,
          rgba(236, 200, 124, 1) 46%,
          rgba(236, 200, 124, 1) 61%,
          rgba(190, 134, 60, 1) 100%
        );

        left: -5%;
        top: 5px;
      }
    }

    p {
      font-family: "Montserrat-md", sans-serif;
      font-weight: 500;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;

      li {
        display: flex;
        justify-content: space-between;
        width: 80%;
        position: relative;

        p:first-child {
          flex-grow: 0;
          padding-right: 10px;
        }

        div {
          flex-grow: 1;
          border-bottom: 2px dotted #000; // Line style similar to the second image
          margin: 0 10px; // Space around the line
        }

        p:last-child {
          white-space: nowrap;
        }
      }
    }
  }
}

.single_price__wrapper > ul > li {
  &:nth-child(3),
  &:nth-child(4) {
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: end;

    p:first-child {
      flex-grow: 0;
      padding-right: 10px;
    }

    div {
      flex-grow: 1;
      border-bottom: 2px dotted #000; // Line style similar to the second image
      margin: 0 10px; // Space around the line
    }

    p:last-child {
      white-space: nowrap;
    }
  }
}

.single_price__bottom {
  padding: 40px;
  background: var(--color-dark);
  margin-top: 20px;
  color: var(--color-white);

  .single_price__bottom__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      width: 60%;
      font-size: 42px;
      font-family: "Montserrat-eb", sans-serif;
      font-weight: 800;
    }

    button {
      padding: 15px 40px;

      background: linear-gradient(
        90deg,
        rgba(190, 134, 60, 1) 0%,
        rgba(236, 200, 124, 1) 46%,
        rgba(236, 200, 124, 1) 61%,
        rgba(190, 134, 60, 1) 100%
      );
      color: var(--color-dark);

      font-size: 24px;
      font-family: "Montserrat-bd", sans-serif;
      font-weight: 700;

      position: relative;

      &::before {
        content: "";
        position: absolute;

        top: 180%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, 50%);

        width: 500px;
        height: 4px;

        background: linear-gradient(
          90deg,
          rgba(190, 134, 60, 1) 0%,
          rgba(236, 200, 124, 1) 46%,
          rgba(236, 200, 124, 1) 61%,
          rgba(190, 134, 60, 1) 100%
        );

        @media (max-width: 1445px) {
          display: none;
        }
      }
    }

    @media (max-width: 1445px) {
      flex-direction: column;
      gap: 20px;
      justify-content: start;
      align-items: start;
    }
  }
}
