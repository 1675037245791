.advantages {
  margin-top: 100px;
}

.advantages__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;

    li {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 10px;

      box-shadow: -1px 0px 5px var(--color-dark);

      background: var(--color-dark);
      padding: 20px;

      width: 280px;
      height: 250px;

      color: var(--color-white);

      div {
        margin-left: 20px;
      }

      p {
        font-size: 24px;
        font-family: "Montserrat-md", sans-serif;
        font-weight: 500;
      }

      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }

      &:nth-child(1) {
        background: #2e2e2e;
      }

      &:nth-child(2) {
        background: #151515;
      }

      &:nth-child(3) {
        background: #0c0c0c;
      }
    }
  }

  h2 {
    font-size: 42px;
    font-family: "Montserrat-sb", sans-serif;
    font-weight: 600;
    text-align: center;
    margin-top: 100px;
    padding-top: 40px;
    padding-bottom: 60px;
    position: relative;

    &::before {
      content: "";
      position: absolute;

      left: 50%;
      right: 50%;
      top: 0%;
      transform: translate(-50%, 50%);

      width: 400px;
      height: 3px;
      background: linear-gradient(
        90deg,
        rgba(190, 134, 60, 1) 0%,
        rgba(236, 200, 124, 1) 46%,
        rgba(236, 200, 124, 1) 61%,
        rgba(190, 134, 60, 1) 100%
      );
    }

    &::after {
      content: "";
      position: absolute;

      left: 50%;
      right: 50%;
      top: 100%;
      transform: translate(-50%, 50%);

      width: 400px;
      height: 3px;
      background: linear-gradient(
        90deg,
        rgba(190, 134, 60, 1) 0%,
        rgba(236, 200, 124, 1) 46%,
        rgba(236, 200, 124, 1) 61%,
        rgba(190, 134, 60, 1) 100%
      );
    }
  }
}
