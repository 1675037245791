.head {
  background: var(--color-dark);
  height: 70vh;
  color: var(--color-white);
}

.head__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 70vh;
  position: relative;

  button {
    position: absolute;

    font-size: 1.5rem;
    font-family: "Montserrat-bd", sans-serif;
    font-weight: 700;

    width: 16rem;
    text-align: center;
    padding: 10px;
    background: linear-gradient(
      90deg,
      rgba(190, 134, 60, 1) 0%,
      rgba(236, 200, 124, 1) 46%,
      rgba(236, 200, 124, 1) 61%,
      rgba(190, 134, 60, 1) 100%
    );

    top: 96%;

    transition: all 0.2s ease;

    &:hover {
      box-shadow: 0px 0px 10px var(--color-dark);
    }
  }
}

.head__text {
  display: flex;
  flex-direction: column;
  gap: 50px;

  h1 {
    font-size: 4rem;
    font-family: "Montserrat-bl", sans-serif;
    font-weight: 900;

    position: relative;

    &::before {
      content: "";
      position: absolute;

      top: 120%;

      width: 400px;
      height: 10px;

      background: linear-gradient(90deg, rgba(190,134,60,1) 0%, rgba(236,200,124,1) 46%, rgba(236,200,124,1) 61%, rgba(190,134,60,1) 100%);;
    }
  }

  p {
    font-size: 1.25rem;
    margin-top: 10px;
  }
}
